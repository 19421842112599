import React from "react"
import alumData from "../../data/alumni/alumniData.json"
import * as styles from "./members.module.css"
import Layout from "../components/layout.js"
import Footer from "../components/footer.js"
import Link from "gatsby-link"

export default function Alumni() {
  return (
    <div>
      <Layout>
        <h1>
          <mark
            style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
          >
            Alumni
          </mark>
        </h1>
        <p style={{ marginTop: `1.5rem`, fontSize: `1.1rem` }}>
          You can find the full list of alumni{" "}
          <Link to="/alumnilist">
            <mark>here.</mark>
          </Link>
        </p>
        {alumData.map(a => (
          <div
            className={styles.YearContainer}
            style={{
              marginTop: `2rem`,
              borderRadius: `25px`,
              padding: `30px`,
            }}
          >
            <h1 style={{ margin: `10px`, fontSize: `3rem` }}>
              <a>{a.year}</a>
            </h1>

            {a.members.map(member => (
              <div className={styles.cont}>
                <div className={styles.card}>
                  <img
                    className={styles.image}
                    src={member.avatar}
                    alt={member.name}
                  />
                  <div className={styles.textContainer}>
                    <h1 className={styles.name} style={{ fontSize: `1.8rem` }}>
                      {member.name}
                    </h1>
                    <h2
                      className={styles.post}
                      style={{ fontSize: `1rem`, marginBottom: `2rem` }}
                    >
                      {member.post}
                    </h2>

                    {member.whereabouts.forEach(lol => (
                      <h3 className={styles.post} style={{ fontSize: `1rem` }}>
                        {lol}
                      </h3>
                    ))}
                    <p className={styles.desc} style={{ fontSize: `1.3rem` }}>
                      {member.description}
                    </p>
                    <div className={styles.socials}>
                      {/*member.socials.map(social => (
                    <div className={styles.socialbtns}>
                      <SocialMediaIconsReact
                        borderColor="#f2627720"
                        borderWidth="2"
                        borderStyle="solid"
                        icon="facebook"
                        iconColor="rgba(255,255,255,1)"
                        backgroundColor="#f26227"
                        iconSize="3"
                        roundness="48%"
                        url={social.twitter}
                        size="30"
                        className={styles.socialbutton}
                      />
                    </div>
                  ))*/}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Layout>
      <Footer></Footer>
    </div>
  )
}
